<template>
    <div v-loading="dataLoading" class="content-wrap">
        <div class="content-select">
            <div class="select-title">{{ fmtInvoiceType(invoice.invoiceType) }}</div>
        </div>
        <div v-if="vatITs.indexOf(invoice.invoiceType)!=-1"
             class="tabPane">
            <div class="content-select content-line">
                <el-row class="row-first">
                    <el-col class="verifyStatus" :span="8">
                        <span>{{ fmtVerifyStatus(invoice.verifyStatus) }}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>查验日期：</label>
                        <span>{{ fmtDate(invoice.verifyTime) }}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>失败原因：</label>
                        <span>{{ invoice.verifyMessage }}</span>
                    </el-col>
                </el-row>
                <el-row class="row-first">
                    <el-col :span="8">
                        <label>发票代码：</label>
                        <span>{{ invoice.invoiceCode }}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>发票号码：</label>
                        <span>{{ invoice.invoiceNo }}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>开票日期：</label>
                        <span>{{ fmtDate(invoice.invoiceTime) }}</span>
                    </el-col>
                </el-row>
                <el-row class="row-first">
                    <el-col :span="8">
                        <label>金额：</label>
                        <span>{{ invoice.amount }} 元</span>
                    </el-col>
                    <el-col :span="8">
                        <label>税额：</label>
                        <span>{{ invoice.taxAmount }} 元</span>
                    </el-col>
                    <el-col :span="8">
                        <label>价税合计：</label>
                        <span>{{ invoice.sumAmount }} 元</span>
                    </el-col>
                </el-row>
                <el-row class="row-first">
                    <el-col :span="8">
                        <label>收票时间：</label>
                        <span>{{ invoice.receiveTime }}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>校验码：</label>
                        <span>{{ invoice.checkCode }}</span>
                    </el-col>
                    <el-col :span="8">
                        <label>数电发票号码：</label>
                        <span>{{ invoice.eInvoiceNo }}</span>
                    </el-col>
                </el-row>
                <el-row class="row-first">
                    <el-col :span="24">
                        <label>备注：</label>
                        <span>{{ invoice.remark }}</span>
                    </el-col>
                </el-row>
                
            </div>
            <div class="content-select content-line">
                <el-row class="select-title invoice-title">
                    <el-col :span="12">
                        <span>购买方</span>
                    </el-col>
                    <el-col :span="12">
                        <span>销售方</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <label>名称：</label>
                        <span>{{ invoice.buyerName }}</span>
                    </el-col>
                    <el-col :span="12">
                        <label>名称：</label>
                        <span>{{ invoice.sellerName }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <label>税号：</label>
                        <span>{{ invoice.buyerTaxNo }}</span>
                    </el-col>
                    <el-col :span="12">
                        <label>税号：</label>
                        <span>{{ invoice.sellerTaxNo }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <label>地址、电话：</label>
                        <span>{{ invoice.buyerAddressTel }}</span>
                    </el-col>
                    <el-col :span="12">
                        <label>地址、电话：</label>
                        <span>{{ invoice.sellerAddressTel }}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <label>开户行及账号：</label>
                        <span>{{ invoice.buyerBankAccount }}</span>
                    </el-col>
                    <el-col :span="12">
                        <label>开户行及账号：</label>
                        <span>{{ invoice.sellerBankAccount }}</span>
                    </el-col>
                </el-row>
            </div>
            <div class="content-select">
                <el-table :data="invoice.items" :header-cell-style="handleHeaderCellStyle" style="width:100%">
                    <el-table-column prop="merchandiseName" label="货物或应税劳务、服务名称" width="300"></el-table-column>
                    <el-table-column prop="specification" label="规格型号" width="150"></el-table-column>
                    <el-table-column prop="unit" label="单位" width="60"></el-table-column>
                    <el-table-column prop="quantity" label="数量" width="80"
                                     :formatter="fmtQuantityPrice"></el-table-column>
                    <el-table-column prop="price" label="单价" width="120"
                                     :formatter="fmtQuantityPrice"></el-table-column>
                    <el-table-column prop="amount" label="金额" width="120"></el-table-column>
                    <el-table-column prop="taxRate" label="税率" width="60"></el-table-column>
                    <el-table-column prop="taxAmount" label="税额" width="120"></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="tabPane">
            <std-n-vat-czc-props
                    v-if="invoice.invoiceType=='TaxiInvoice'"
                    :invoice="invoice"></std-n-vat-czc-props>
            <std-n-vat-dep-props
                    v-if="invoice.invoiceType=='QuotaInvoice'"
                    :invoice="invoice"></std-n-vat-dep-props>
            <std-n-vat-fjp-props
                    v-if="invoice.invoiceType=='PlaneInvoice'"
                    :invoice="invoice"></std-n-vat-fjp-props>
            <std-n-vat-glf-props
                    v-if="invoice.invoiceType=='TollInvoice'"
                    :invoice="invoice"></std-n-vat-glf-props>
            <std-n-vat-hcp-props
                    v-if="invoice.invoiceType=='TrainInvoice'"
                    :invoice="invoice"></std-n-vat-hcp-props>
            <std-n-vat-jdp-props
                    v-if="invoice.invoiceType=='GenerMachineInvoice'"
                    :invoice="invoice"></std-n-vat-jdp-props>
            <std-n-vat-kyp-props
                    v-if="invoice.invoiceType=='PassengerInvoice'"
                    :invoice="invoice"></std-n-vat-kyp-props>
        </div>
    </div>
</template>

<script>
    import StdNVatCzcProps from "./StdNVatCzcProps.vue";
    import StdNVatDepProps from "./StdNVatDepProps.vue";
    import StdNVatFjpProps from "./StdNVatFjpProps.vue";
    import StdNVatGlfProps from "./StdNVatGlfProps.vue";
    import StdNVatHcpProps from "./StdNVatHcpProps.vue";
    import StdNVatJdpProps from "./StdNVatJdpProps.vue";
    import StdNVatKypProps from "./StdNVatKypProps.vue";
    import {tableStyle} from "@/util/mixins";
    import {FmtEnum} from "@/util/enumUtil";

    export default {
        name: "StdInvoiceDetail",
        components: {
            StdNVatCzcProps,
            StdNVatDepProps,
            StdNVatFjpProps,
            StdNVatGlfProps,
            StdNVatHcpProps,
            StdNVatJdpProps,
            StdNVatKypProps
        },
        mixins: [tableStyle],
        props: ["invoice", "dataLoading"],
        data() {
            return {
                invoiceTypeSet: [],
                verifyStatusSet: [],
                vatITs: ['GenerInvoice', 'EleInvoice', 'EleSpecInvoice', 'VolInvoice', 'VehInvoice', 'SpecInvoice', 'EleTollInvoice']
            };
        },
        created() {
            this.invoiceTypeSet = this.$store.state.enums.InvoiceType;
            this.verifyStatusSet = [
                {label: '全部', value: ""},
                {label: '无需查验', value: "0"},
                {label: '查验成功', value: "1"},
                {label: '查验出错', value: "2"},
                {label: '正在查验', value: "3"}
            ];
        },
        methods: {
            fmtQuantityPrice(row, column, cellValue, index) {
                return (cellValue != null && cellValue > 0) ? cellValue : "";
            },
            fmtVerifyStatus(val) {
                return FmtEnum(this.verifyStatusSet, val);
            },
            fmtInvoiceType(val) {
                return FmtEnum(this.invoiceTypeSet, val);
            },
            fmtDate(val) {
                if (!val) return '';
                return this.$moment(val).format("YYYY-MM-DD");
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-wrap {
        padding: 20px;
        color: #666;

        ::v-deep .el-tabs__header {
            padding: 0;
        }
    }

    .tabPane {
        border: 0;
    }

    .invoice-title {
        margin-bottom: 24px !important;
    }

    .content-select {
        margin-top: 24px;
        padding-bottom: 16px;
        box-shadow: none;

        .el-row {
            margin-bottom: 16px;

            span {
                color: #333333;
            }
        }

        .verifyStatus {
            color: #ff4d4f;
        }
    }

    .content-line {
        border-bottom: 1px solid #cccccc;
    }
</style>
