import {get, post, download} from "./index"

/**
 * 导出发票列表
 * @param data
 * @returns {Promise<*>}
 */
export const cInvoicesExport = data => {
    return download("/person/ticket/holder/cInvoicesExport", data).then(res => res);
};

/**
 * 分页获取发票列表
 * @param data 查询入参
 * @returns {Promise<any>} 返回数据列表
 */
export const getInvoiceListByPage = data => {
    return post("/person/ticket/holder/getInvoiceListByPage", data).then(res => res);
};

/**
 * 获取发票详细信息
 * @param data 发票Id
 * @returns {Promise<any>} 返回发票详细信息
 */
export const getInvoiceDetailById = params => {
    return get("/person/ticket/holder/getInvoiceDetailById", params).then(res => res);
};

/**
 * 获取发票详细信息
 * @param data 发票Id
 * @returns {Promise<any>} 返回发票详细信息
 */
export const delInvoiceDetailById = params => {
    return post("/person/ticket/holder/delInvoiceDetailById", {}, {params: params}).then(res => res);
};

/**
 * 批量下载发票源文件
 * @param data
 * @returns {Promise<*>}
 */
export const cInvoicesDownload = data => {
    return download("/person/ticket/holder/cInvoicesDownload", data).then(res => res);
};

/**
 * 下载影像
 * @param data
 * @returns {Promise<*>}
 */
export const downloadImage = data => {
    return download("/person/ticket/holder/downloadImage", data).then(res => res);
};

/**
 * 发票影像
 * @param {*} data
 */
export const getCInvoiceImageById = (data) => {
    return get("/person/ticket/holder/getCInvoiceImageById/" + data).then(res => res);
}

/**
 * 批量获取影像
 * @param data
 * @returns {Promise<any | never>}
 */
export const getCInvoiceImagesByIds = (data) =>{
    return post("/person/ticket/holder/getCInvoiceImagesByIds",data).then(res => res);
}
