<!--非增值税-出租车票-->
<template>
    <div class="content-select">
        <el-row class="row-first">
            <el-col :span="12">
                <label>发票代码：</label>
                <span>{{ invoice.invoiceCode }}</span>
            </el-col>
            <el-col :span="12">
                <label>发票号码：</label>
                <span>{{ invoice.invoiceNo }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>城市：</label>
                <span>{{ invoice.city }}</span>
            </el-col>
            <el-col :span="12">
                <label>开票日期：</label>
                <span>{{ invoice.invoiceTime }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>上车时间：</label>
                <span>{{ invoice.getOnTime }}</span>
            </el-col>
            <el-col :span="12">
                <label>下车时间：</label>
                <span>{{ invoice.getOffTime }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>公里：</label>
                <span>{{ invoice.kilometre }}</span>
            </el-col>
            <el-col :span="12">
                <label>金额：</label>
                <span>{{ invoice.sumAmount }} 元</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>收票时间：</label>
                <span>{{ invoice.receiveTime }}</span>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: "StdNVatCzcProps",
        props: {
            invoice: {
                type: Object,
                default: () => {
                    return {
                        invoiceCode: '',
                        invoiceNo: '',
                        city: '',
                        invoiceTime: null,
                        getOnTime: '',
                        getOffTime: '',
                        kilometre: '',
                        sumAmount: '',
                        receiveTime: null
                    };
                }
            }
        },
        data() {
            return {};
        }
    }
</script>
<style>
</style>
