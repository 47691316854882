<template>
  <div>
    <el-upload action=".ofd" multiple :auto-upload="false" :on-change="handleUpload" :on-exceed="handleExceed" :show-file-list="false">
      <span @click="handleUploadClick">
        <img v-if="type == 'text'" src="@/assets/img/invoiceCenter/OFD@2x.png" />
        <el-button :type="type">OFD文件</el-button>
      </span>
    </el-upload>
    <!--发票上传-->
    <el-dialog append-to-body :visible.sync="resultVisible" :show-close="false" width="40%" class="result-dialog" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog-wrap">
        <div class="dialog-header">
          <span class="dialog-title">OFD解析结果</span>
          <span class="dialog-total">共{{ fileResult.size }}张</span>
        </div>
        <div class="dialog-content">
          <div class="success-items" v-for="item in fileResult.success" :key="item.name">
            <label>{{ item | filterLongValue }}</label>
            <span class="item-success">成功</span>
          </div>
          <div class="error-items" v-for="error in fileResult.error" :key="error">
            <label>{{ error | filterLongValue }}</label>
            <span class="item-error">失败</span>
          </div>
          <div class="repeat-items" v-for="item in fileResult.repeat" :key="item.id">
            <div>
              <label>{{ item.name | filterLongValue }}</label>
              <div class="item-content">
                <label>发票代码：</label>
                <span>{{ item.invoiceCode }}</span>
              </div>
              <div class="item-content">
                <label>发票号码：</label>
                <span>{{ item.invoiceNum }}</span>
              </div>
            </div>
            <span class="item-repeat">重复</span>
          </div>
        </div>
        <div v-if="isLocal && business == 'LOCAL'" slot="footer" class="dialog-footer">
          <el-button :loading="closeResultLoading" type="default" @click="handleUploadNext">继续上传 </el-button>
          <el-button :loading="closeResultLoading" type="primary" @click="closeResultDialog">返回收票中心 </el-button>
        </div>
        <div v-else slot="footer" class="dialog-footer">
          <el-button :loading="closeResultLoading" type="primary" @click="closeResultDialog">确定 </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uploadOFDFiles } from '@/service/tickets';
import { uploadOFDFromLocal, getRecognizeBatchNo, ofdRecognize } from '@/service/invoice-center';
export default {
  name: 'UploadOfdInvoice',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    paramsData:{
      type:null,
    },
    isLocal: {
      type: Boolean,
      default: false
    },
    isBlend: {
      type: Boolean,
      default: false
    },
    business: {
      type: String,
      default: 'LOCAL'
    },
    settlementId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileSize: 0,
      fileResult: {
        success: [],
        repeat: [],
        error: [],
        size: 0
      },
      batchNo: '',
      closeResultLoading: false,
      resultVisible: false,
      uploadDisabled: false
    };
  },
  filters: {
    filterLongValue(value) {
      return value.length > 10 ? value.substring(0, 10) + '...' : value;
    }
  },
  methods: {
    async ofdRecognize () {
      let res = await ofdRecognize(this.paramsData)
      console.log(res,'res')
    },
    // 上传OFD
    handleUpload(file) {
      console.log(file, '上传OFD----');
      this.$emit('handlerUploadFile',file)
      
      if (!/\.ofd/.test(file.name)) {
        return;
      }
      this.fileSize += 1;
      this.resultVisible = true;
      this.closeResultLoading = true;
      this.ofdRecognize()
      // let formData = new FormData();
      // formData.append('fileName', file.name);
      // formData.append('file', file.raw);
      // formData.append('business', this.business);
      // formData.append('isBlend', this.isBlend);
      // formData.append('settlementId', this.settlementId);
      // formData.append('batchNo', this.batchNo);
      // formData.append('fileFrom', 'UPLOAD');
      // if (this.isLocal) {
      //   this.localUpload(formData);
      // } else {
      //   this.personUpload(formData);
      // }
    },
    // 个人票夹上传
    async personUpload(formData) {
      const { code, repeat, failed, success } = await uploadOFDFiles(formData);
      this.reviewResult(code, repeat, failed, success);
    },
    // 本地文件上传OFD
    async localUpload(formData) {
      const { code, repeat, failed, success } = await uploadOFDFromLocal(formData);
      this.reviewResult(code, repeat, failed, success);
    },
    // 更新返回结果视图
    reviewResult(code, repeat, failed, success) {
      this.fileResult.size += 1;
      if (this.fileResult.size == this.fileSize) {
        this.closeResultLoading = false;
      }
      switch (code) {
        case '0':
          this.fileResult.error.push(failed);
          return;
        case '2':
          this.fileResult.repeat.push(repeat);
          return;
        default:
          this.fileResult.success.push(success);
          return;
      }
    },
    // 上传错误捕获
    handleExceed() {
      this.toast('最多可上传九张发票', 'error');
    },
    // 获取批次号
    async handleUploadClick() {
      if (this.isLocal) {
        const { success, data } = await getRecognizeBatchNo();
        if (success) {
          this.batchNo = data;
          console.log('batchNo', this.batchNo);
        } else {
          this.toast('未获取到相应批次号');
        }
      }
    },
    closeResultDialog() {
      this.fileSize = 0;
      this.fileResult.size = 0;
      this.fileResult.repeat = [];
      this.fileResult.error = [];
      this.fileResult.success = [];
      this.resultVisible = false;
      this.$emit('result-dialog-close', this.batchNo);
    },
    // 继续上传
    handleUploadNext() {
      this.fileSize = 0;
      this.fileResult.size = 0;
      this.fileResult.repeat = [];
      this.fileResult.error = [];
      this.fileResult.success = [];
      this.batchNo = '';
      this.resultVisible = false;

      this.handleUploadClick();
      // [0] accept：pdf
      // [1] accept: jpg、png
      // [2] accept: #
      document.getElementsByClassName('el-upload__input')[2].click();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 24px !important;
}

.dialog-wrap {
  display: flex;
  font-size: 14px;
  flex-direction: column;

  .dialog-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    justify-content: space-between;

    .dialog-title {
      color: #333333;
      font-size: 18px;
    }

    .dialog-total {
      color: #999999;
      font-size: 14px;
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;

    .success-items,
    .repeat-items,
    .error-items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 12px;
      text-align: left;
      color: #666666;
    }

    .item-content {
      color: #999999;
      margin-top: 8px;
      padding-left: 16px;

      span {
        padding-left: 8px;
      }
    }

    .item-success {
      color: #09bb07;
    }

    .item-repeat {
      color: #ff7a08;
    }

    .item-error {
      color: #f5222d;
    }
  }

  .dialog-footer {
    text-align: right;
  }
}

.el-button--text {
  padding: 0;
  color: #666666;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-right: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
}

.el-button:hover {
  color: #1890ff;
}

img {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
</style>
