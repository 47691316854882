import { post, get } from './index';

/**
 * 上传文件
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadOFDFiles = (data) => {
  return post('/person/ticket/holder/uploadFiles', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res.data);
};

export const uploadXMLFiles = (data) => {
  return post('/image/invoice/xmlUpload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};
