<template>
    <div>
        <div class="content-select">
            <div class="select-content">
                <el-form class="select-form" :model="selectForm">
                    <div>
                        <el-form-item label="发票类型">
                            <el-select v-model="selectForm.invoiceType" placeholder="请选择">
                                <el-option v-for="item in invoiceTypeSet"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="开票日期">
                            <el-date-picker
                                    v-model="selectForm.invoiceTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="dateOption"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="乘坐人员">
                            <el-input v-model.trim="selectForm.rider" placeholder="请输入乘坐人员" maxlength="32"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                    </div>
                    <div v-show="selectExpended">
                        <el-form-item label="发票代码">
                            <el-input v-model.trim="selectForm.invoiceCode" placeholder="请输入发票代码" maxlength="12"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                        <el-form-item label="发票号码">
                            <el-input v-model.trim="selectForm.invoiceNo" placeholder="请输入发票号码" maxlength="8"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                        <el-form-item label="收票日期">
                            <el-date-picker
                                    v-model="selectForm.receiveTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="dateOption"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div v-show="selectExpended">
                        <el-form-item label="电子票号">
                            <el-input v-model.trim="selectForm.ticketNo" placeholder="电子票号/21位码" maxlength="32"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                         <el-form-item label="数电发票号码">
                            <el-input v-model.trim="selectForm.eInvoiceNo" placeholder="请输入数电发票号码" maxlength="20"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                          <el-form-item  >
                        </el-form-item>
                    </div>
                </el-form>
                <div class="select-button">
                    <select-button show-status @list-close="handleListClose"
                                   @list-show="handleListShow" @select="handleSelect"
                                   @reset="handleReset"></select-button>
                </div>
            </div>
        </div>
        <div class="content-main">
            <p-header spacer :title="'票据列表'">
                <el-button type="default"
                           :loading="exportExecuting"
                           :disabled="exportExecuting"
                           @click="handleExportBatch">导出数据
                </el-button>
                <el-button type="primary"
                           :loading="downloadExecuting"
                           :disabled="downloadExecuting"
                           @click="handleDownloadBatch">下载影像
                </el-button>
                <el-button type="primary"
                           :loading="printExecuting"
                           :disabled="printExecuting"
                           @click="handlePrintBatch">打印影像
                </el-button>
            </p-header>
            <div class="main-table">
                <el-table
                        ref="multipleTable"
                        :data="tableData"
                        stripe
                        border
                        v-loading="tableLoading"
                        @row-click="handleSelectCurrentLine"
                        :header-cell-style="handleHeaderCellStyle"
                        @selection-change="handleSelection"
                        style="width: 100%"
                >
                    <el-table-column type="selection" width="50"></el-table-column>
                    <el-table-column type="index" label="序号" width="50"/>
                    <el-table-column prop="invoiceType" label="发票类型" width="150" :formatter="fmtInvoiceType"/>
                    <el-table-column prop="invoiceTime" label="发票日期" min-width="120" :formatter="handleDateFormat"/>
                    <el-table-column prop="rider" label="乘坐人员" width="100"/>
                    <el-table-column prop="eInvoiceNo" label="数电发票号码" min-width="120" />

                    <el-table-column prop="invoiceCode" label="发票代码" width="120"/>
                    <el-table-column prop="invoiceNo" label="发票号码" width="100"/>

                    <el-table-column label="电子票号/21位码" width="100">
                        <template slot-scope="scope">
                            {{(scope.row.ticketNo==null||scope.row.ticketNo=='')?scope.row.train21No:scope.row.ticketNo}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="sumAmount" label="价税合计" width="100"/>
                    <el-table-column prop="receiveTime" label="收票日期" min-width="120" :formatter="handleDateFormat"/>
                    <el-table-column prop="operate" label="操作" width="140" fixed="right">
                        <template slot-scope="scope">
                            <div class="operate-button">
                                <el-button type="text" @click="handleDetail(scope.row)">详情</el-button>
                                <el-button type="text" :disabled="!scope.row.imageId" @click="handleImage(scope.row)">影像</el-button>
                                <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="main-page">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="page.current"
                        :page-sizes="pageSizes"
                        :page-size="page.size"
                        layout="total, sizes, prev, pager, next"
                        :total="page.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import PHeader from "@/components/pdt/PHeader";
    import {
        dateOption,
        tableStyle,
        pagingMethods
    } from "@/util/mixins";
    import {
        cInvoicesExport,
        getInvoiceListByPage
    } from "@/service/person-walt";
    import {FmtEnum} from "@/util/enumUtil";

    export default {
        name: "OthInvoiceList",
        props: [
            "delete-invoice",
            "detail-invoice",
            "image-invoice",
            "batch-download",
            "batch-export",
            "batch-print"],
        mixins: [dateOption, tableStyle, pagingMethods],
        components: {
            PHeader
        },
        data() {
            return {
                selectExpended: false,
                selectForm: {
                    isVat: false,
                    invoiceType: "",
                    invoiceCode: "",
                    invoiceNo: "",
                    rider: "",
                    ticketNo: "",
                    invoiceTime: null,
                    receiveTime:'',
                    eInvoiceNo:''
                },
                invoiceTypeSet: [],
                tableData: [],
                tableLoading: false,
                selections: [],
                exportExecuting: false,
                downloadExecuting: false,
                printExecuting: false
            }
        },
        created() {
            this.invoiceTypeSet = [
                {label: '全部', value: ""},
                {label: '定额发票', value: "QuotaInvoice"},
                {label: '飞 机 票', value: "PlaneInvoice"},
                {label: '火 车 票', value: "TrainInvoice"},
                {label: '客 运 票', value: "PassengerInvoice"},
                {label: '出租车票', value: "TaxiInvoice"},
                {label: '通用机打发票', value: "GeneralMachineInvoice"},
                {label: '过 路 费', value: "TollInvoice"}
            ];
            this.handleSelect();
        },
        methods: {
            handleReset() {
                this.selectForm = {
                    isVat: false,
                    invoiceType: "",
                    invoiceCode: "",
                    invoiceNo: "",
                    rider: "",
                    ticketNo: "",
                    invoiceTime: null,
                    receiveTime:""
                };
            },
            handleSelect() {
                this.page.current = 1;
                this.page.searchCount = true;
                this.reqGetInvoiceListByPage();
            },
            submitForm() {
                this.reqGetInvoiceListByPage();
            },
            handleSelectCurrentLine(row, column) {
                if (column.label == '操作') return;
                this.$refs.multipleTable.toggleRowSelection(row);
            },
            handleSelection(row) {
                this.selections = row;
            },
            handleExportBatch() {
                this.exportExecuting = true;
                this.$emit("batch-export", this.selections, () => {
                    this.exportExecuting = false;
                });
            },
            handleDownloadBatch() {
                this.downloadExecuting = true;
                this.$emit("batch-download", this.selections, () => {
                    this.downloadExecuting = false;
                });
            },
            handlePrintBatch() {
                this.printExecuting = true;
                this.$emit("batch-print", this.selections, () => {
                    this.printExecuting = false;
                });
            },
            handleDetail(row) {
                this.$emit("detail-invoice", row);
            },
            handleImage(row) {
                this.$emit("image-invoice", row);
            },
            handleDelete(row) {
                this.$emit("delete-invoice", row, () => {
                    const id = row.id;
                    this.tableData.splice(this.tableData.findIndex((item) => item.id == id), 1);
                });
            },
            handleListClose() {
                this.selectExpended = false;
            },
            handleListShow() {
                this.selectExpended = true;
            },
            reqGetInvoiceListByPage() {
                const param = {
                    filter: this.selectForm,
                    paging: this.page
                };
                this.tableLoading = true;
                getInvoiceListByPage(param).then(res => {
                    if (res.success) {
                        this.tableData = res.data.records;
                        delete res.data.records;
                        this.page = Object.assign(this.page, res.data, {
                            searchCount: false
                        });
                    }
                    this.tableLoading = false;
                });
            },
            fmtInvoiceType(row, column, cellValue, index) {
                return FmtEnum(this.invoiceTypeSet, cellValue);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/style/select.scss";

    .select-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        padding: 16px 24px;

        .select-items {
            display: inline-flex;

            span {
                color: #3d94ff;
                margin-right: 20px;
                font-weight: 500;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                line-height: 22px;
            }

            img {
                width: 20px;
                height: 20px;
                vertical-align: sub;
                margin-right: 4px;
            }

            span:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .select-title:before {
            content: "|";
            display: inline-block;
            font-size: 16px;
            margin-right: 10px;
            background-color: #333;
        }
    }

    .content-main {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        justify-content: space-between;

        .main-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .header-title {
                color: #666666;
                font-size: 16px;
            }
        }

        .main-table {
            margin-top: 10px;

            .operate-button {
                .el-button {
                    padding: 0;
                }
            }
        }

        .main-page {
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    ::v-deep .el-dialog__header {
        display: none;
    }

    ::v-deep .el-dialog__body {
        padding: 24px !important;
    }
</style>
