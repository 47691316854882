<template>
    <div>
        <div class="content-select">
            <div class="select-content">
                <el-form class="select-form" :model="selectForm">
                    <div>
                        <el-form-item label="发票类型">
                            <el-select v-model="selectForm.invoiceType" placeholder="请选择">
                                <el-option v-for="item in invoiceTypeSet"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="发票代码">
                            <el-input v-model.trim="selectForm.invoiceCode" placeholder="请输入发票代码" maxlength="12"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                        <el-form-item label="发票号码">
                            <el-input v-model.trim="selectForm.invoiceNo" placeholder="请输入发票号码" maxlength="8"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                    </div>
                    <div v-show="selectExpended">
                        <el-form-item label="验章状态">
                            <el-select v-model="selectForm.sealStatus" placeholder="请选择">
                                <el-option v-for="item in sealStatusSet"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="查验状态">
                            <el-select v-model="selectForm.verifyStatus" placeholder="请选择">
                                <el-option v-for="item in verifyStatusSet"
                                           :key="item.value"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="收票日期">
                            <el-date-picker
                                    v-model="selectForm.receiveTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="dateOption"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div v-show="selectExpended">
                        <el-form-item label="开票日期">
                            <el-date-picker
                                    v-model="selectForm.invoiceTime"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="dateOption"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                         <el-form-item label="数电发票号码">
                            <el-input v-model.trim="selectForm.eInvoiceNo" placeholder="请输入数电发票号码" maxlength="20"
                                      @keyup.enter.native="reqGetInvoiceListByPage()"/>
                        </el-form-item>
                         <el-form-item >
                        </el-form-item>
                    </div>
                </el-form>
                <div class="select-button">
                    <select-button show-status @list-close="handleListClose"
                                   @list-show="handleListShow" @select="handleSelect"
                                   @reset="handleReset"></select-button>
                </div>
            </div>
        </div>
        <div class="content-main">
            <p-header spacer :title="'票据列表'">
                <el-button type="default"
                           :loading="exportExecuting"
                           :disabled="exportExecuting"
                           @click="handleExportBatch">导出数据
                </el-button>
                <el-button type="default"
                           :loading="downloadExecuting"
                           :disabled="downloadExecuting"
                           @click="handleDownloadBatch">下载影像
                </el-button>
                <el-button type="default"
                           :loading="printExecuting"
                           :disabled="printExecuting"
                           @click="handlePrintBatch">打印影像
                </el-button>
                <upload-ofd-file @result-dialog-close="submitForm" style="margin-left: 10px;"></upload-ofd-file>
            </p-header>
            <div class="main-table">
                <el-table
                        :data="tableData"
                        stripe
                        border
                        ref="multipleTable"
                        v-loading="tableLoading"
                        @row-click="handleSelectCurrentLine"
                        :header-cell-style="handleHeaderCellStyle"
                        @selection-change="handleSelection"
                        style="width: 100%"
                >
                    <el-table-column type="selection" fixed width="50"></el-table-column>
                    <el-table-column type="index" label="序号" width="50"/>
                    <el-table-column prop="invoiceType" label="发票类型" width="150" :formatter="fmtInvoiceType"/>
                    <el-table-column prop="invoiceTime" label="开票日期" min-width="120" :formatter="handleDateFormat"/>
                    <el-table-column prop="invoiceCode" label="发票代码" width="120"/>
                    <el-table-column prop="invoiceNo" label="发票号码" width="100"/>
                    <el-table-column prop="eInvoiceNo" label="数电发票号码" min-width="120" />
                    <el-table-column prop="amount" label="金额" width="100"/>
                    <el-table-column prop="taxAmount" label="税额" width="100"/>
                    <el-table-column prop="sumAmount" label="价税合计" width="100"/>
                    <el-table-column prop="imageType" label="影像类型" width="100" :formatter="fmtInvoiceImageType"/>
                    <el-table-column prop="verifyStatus" label="查验状态" width="100" :formatter="fmtVerifyStatus"/>
                    <el-table-column prop="sealStatus" label="验章状态" width="100" :formatter="fmtSealStatus"/>
                    <el-table-column prop="receiveTime" label="收票日期" min-width="120" :formatter="handleDateFormat"/>
                    <el-table-column prop="operate" label="操作" width="140" fixed="right">
                        <template slot-scope="scope">
                            <div class="operate-button">
                                <el-button type="text" @click="handleDetail(scope.row)">详情</el-button>
                                <el-button type="text" :disabled="!scope.row.imageId" @click="handleImage(scope.row)">
                                    影像
                                </el-button>
                                <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="main-page">
                <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="page.current"
                        :page-sizes="pageSizes"
                        :page-size="page.size"
                        layout="total, sizes, prev, pager, next"
                        :total="page.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import PHeader from "@/components/pdt/PHeader.vue";
    import UploadOfdFile from "./UploadOfdFile.vue";
    import {
        dateOption,
        tableStyle,
        pagingMethods
    } from "@/util/mixins";
    import {
        cInvoicesExport,
        getInvoiceListByPage,
        cInvoicesDownload,
        downloadImage,
        getInvoiceDetailById,
        delInvoiceDetailById
    } from "@/service/person-walt";
    import {FmtEnum} from "@/util/enumUtil";

    export default {
        name: "VatInvoiceList",
        props: [
            "delete-invoice",
            "detail-invoice",
            "image-invoice",
            "batch-download",
            "batch-export",
            "batch-print"],
        mixins: [dateOption, tableStyle, pagingMethods],
        components: {
            PHeader,
            UploadOfdFile
        },
        data() {
            return {
                invoiceImageTypeSet: this.$store.state.enums.InvoiceImageType,
                invoiceTypeSet: [],
                verifyStatusSet: [
                    {label: '全部', value: ""},
                    {label: '无需查验', value: "0"},
                    {label: '查验成功', value: "1"},
                    {label: '查验出错', value: "2"},
                    {label: '正在查验', value: "3"}
                ],
                sealStatusSet: [
                    {label: '全部', value: ""},
                    {label: '验章有效', value: "1"},
                    {label: '验章无效', value: "0"}
                ],
                selectExpended: false,
                selectForm: {
                    isVat: true,
                    invoiceType: "",
                    invoiceCode: "",
                    invoiceNo: "",
                    sealStatus: "",
                    verifyStatus: "",
                    receiveTime: null,
                    invoiceTime: null,
                    eInvoiceNo:''
                },
                selections: [],
                tableLoading: false,
                tableData: [],
                exportExecuting: false,
                downloadExecuting: false,
                printExecuting: false
            }
        },
        created() {
            this.invoiceTypeSet = [
              { label: '全部', value: '' },
              { label: '增值税专用发票', value: 'SpecInvoice' },
              { label: '增值税普通发票', value: 'GenerInvoice' },
              { label: '增值税电子普通发票', value: 'EleInvoice' },
              { label: '增值税电子专用发票', value: 'EleSpecInvoice' },
              { label: '增值税电子发票(通行费)', value: 'EleTollInvoice' },
              { label: '增值税普通发票(卷票)', value: 'VolInvoice' },
              { label: '机动车销售统一发票', value: 'VehInvoice' }
            ];
            this.handleSelect();
        },
        methods: {
            handleReset() {
                this.selectForm = {
                    isVat: true,
                    invoiceType: "",
                    invoiceCode: "",
                    invoiceNo: "",
                    sealStatus: "",
                    verifyStatus: "",
                    receiveTime: null,
                    invoiceTime: null,
                    eInvoiceNo:''
                };
            },
            handleSelect() {
                this.page.current = 1;
                this.page.searchCount = true;
                this.reqGetInvoiceListByPage();
            },
            submitForm() {
                this.handleSelect();
            },
            handleSelectCurrentLine(row, column) {
                if (column.label == '操作') return;
                this.$refs.multipleTable.toggleRowSelection(row);
            },
            handleSelection(row) {
                this.selections = row;
            },
            handleExportBatch() {
                this.exportExecuting = true;
                this.$emit("batch-export", this.selections, () => {
                    this.exportExecuting = false;
                });
            },
            handleDownloadBatch() {
                this.downloadExecuting = true;
                this.$emit("batch-download", this.selections, () => {
                    this.downloadExecuting = false;
                });
            },
            handlePrintBatch() {
                this.printExecuting = true;
                this.$emit("batch-print", this.selections, () => {
                    this.printExecuting = false;
                });
            },
            handleDetail(row) {
                this.$emit("detail-invoice", row);
            },
            handleImage(row) {
                this.$emit("image-invoice", row);
            },
            handleDelete(row) {
                this.$emit("delete-invoice", row, () => {
                    const id = row.id;
                    this.tableData.splice(this.tableData.findIndex((item) => item.id == id), 1);
                });
            },
            // 收起筛选列表
            handleListClose() {
                this.selectExpended = false;
            },
            // 展开筛选列表
            handleListShow() {
                this.selectExpended = true;
            },
            reqGetInvoiceListByPage() {
                const param = {
                    filter: this.selectForm,
                    paging: this.page
                };
                this.tableLoading = true;
                getInvoiceListByPage(param).then(res => {
                    if (res.success) {
                        this.tableData = res.data.records;
                        console.log(this.tableData,"--this.tableData---")

                        delete res.data.records;
                        this.page = Object.assign(this.page, res.data, {
                            searchCount: false
                        });
                    }
                    this.tableLoading = false;
                });
            },
            fmtInvoiceImageType(row, column, cellValue, index) {
                return FmtEnum(this.invoiceImageTypeSet, cellValue);
            },
            fmtVerifyStatus(row, column, cellValue, index) {
                return FmtEnum(this.verifyStatusSet, cellValue);
            },
            fmtInvoiceType(row, column, cellValue, index) {
                return FmtEnum(this.invoiceTypeSet, cellValue);
            },
            fmtSealStatus(row, column, cellValue, index) {
                 if (cellValue == null) {
                     return "--";
                }
                return cellValue ? "验章有效" : "验章无效";
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/style/select.scss";

    .select-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        padding: 16px 24px;

        .select-items {
            display: inline-flex;

            span {
                color: #3d94ff;
                margin-right: 20px;
                font-weight: 500;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                line-height: 22px;
            }

            img {
                width: 20px;
                height: 20px;
                vertical-align: sub;
                margin-right: 4px;
            }

            span:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .select-title:before {
            content: "|";
            display: inline-block;
            font-size: 16px;
            margin-right: 10px;
            background-color: #333;
        }
    }

    .content-main {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
        justify-content: space-between;

        .main-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .header-title {
                color: #666666;
                font-size: 16px;
            }
        }

        .main-table {
            margin-top: 10px;

            .operate-button {
                .el-button {
                    padding: 0;
                }
            }
        }

        .main-page {
            padding: 20px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    ::v-deep .el-dialog__header {
        display: none;
    }

    ::v-deep .el-dialog__body {
        padding: 24px !important;
    }
</style>
