<!--非增值税-飞机票-->
<template>
    <div class="content-select">
        <el-row class="row-first">
            <el-col :span="12">
                <label>乘机人：</label>
                <span>{{ invoice.rider }}</span>
            </el-col>
            <el-col :span="12">
                <label>客票号：</label>
                <span>{{ invoice.ticketNo }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>出发站：</label>
                <span>{{ invoice.departure }}</span>
            </el-col>
            <el-col :span="12">
                <label>目的站：</label>
                <span>{{ invoice.destination }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>航班号：</label>
                <span>{{ invoice.flight }}</span>
            </el-col>
            <el-col :span="12">
                <label>座位等级：</label>
                <span>{{ invoice.seatClass }}</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>乘机日期：</label>
                <span>{{ invoice.invoiceTime }}</span>
            </el-col>
            <el-col :span="12">
                <label>燃油附加费：</label>
                <span>{{ invoice.surcharge }} 元</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>票价：</label>
                <span>{{ invoice.ticketPrice }}</span>
            </el-col>
            <el-col :span="12">
                <label>金额：</label>
                <span>{{ invoice.sumAmount }} 元</span>
            </el-col>
        </el-row>
        <el-row class="row-first">
            <el-col :span="12">
                <label>收票时间：</label>
                <span>{{ invoice.receiveTime }}</span>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: "StdNVatFjpProps",
        props: {
            invoice: {
                type: Object,
                default: () => {
                    return {
                        rider: '',
                        ticketNo: '',
                        departure: '',
                        destination: '',
                        flight: '',
                        seatClass: '',
                        invoiceTime: null,
                        surcharge: '',
                        ticketPrice: null,
                        sumAmount: null,
                        receiveTime: null
                    };
                }
            }
        },
        data() {
            return {};
        }
    }
</script>
<style>
</style>
